<template>
  <div>
    <StudentFAQ />
    <div>
      <h2>How do I perform a Take-off and Landing Distance Required check before my flight?</h2>
      <p>
        All Aircraft flight manuals (PoH) contain a section on <b>Performance</b> calculations,
        including Take-off Distance Required (TODR) and Landing Distance Required (LDR). For
        some aircraft, the calculation is relatively simple and for others, many factors must
        be incorporated into the calculation.
      </p>
      <p>
        You will have learned in your initial training that various factors have an effect on TODR and LDR.
      </p>
      <div class="list-container">
        <ul class="steps-list">
          <li>Wind direction</li>
          <li>Aircraft weight</li>
          <li>Aircraft power available</li>
          <li>Field elevation</li>
          <li>Runway surface</li>
          <li>Runway slope</li>
          <li>Propeller pitch setting</li>
          <li>Outside air temperature</li>
          <li>Ambient air pressure</li>
        </ul>
      </div>
      <p>
        Simpler aircraft, such as the Eurofox 3K, provide a very simple method of performing
        the required calculations where these factors are not directly incorporated. More
        complex aircraft require you to incorporate these factors into your calculation
        &mdash; you will learn how to do this as you progress through your training.
        Although a simple aircraft may not directly incorporate these parameters
        into a final calculation, it is important to take them into consideration for your
        planned operation. For example, if you have calculated a TODR at 600 metres, with only
        50 metres remaining, and the temperature outside is 35&#x2103;, perhaps you might
        choose to reconsider your decision to go.
      </p>
      <p>
        Many incidents have occurred throughout history where an aircraft has not achieved the
        required take-off or landing distance performance. You can imagine what it looks like;
        taking-off where trees or other obstacles start filling the windscreen and you haven't
        even reached rotation speed yet. Or landing on a wet runway with maximum available braking,
        but the fence at the other end is rapidly approaching. Learn from the mistakes of others
        &mdash; it's the cheapest lesson!
      </p>
      <p>
        It's not only simply a good idea, but it is also a <b>legal responsibility</b> of the
        <b>Pilot in Command</b> to perform both a Take-off and Landing Distance Required
        calculation for all planned departure, destination and alternate aerodromes <i>(CAO 20.7.4)</i>.
        All Flightscope Aviation staff are willing and open to help you with learning more about your pre-flight requirements, including
        TODR/LDR. If you have a quick question, just ask! If you feel you need a bit more help than that, please ring the office
        and schedule a briefing session with an instructor. We want you all to have fun, and be safe while doing so.
      </p>
      <p>
        Important tip: Don't forget to incorporate into your TODR/LDR calculations: <b>Civil Aviation Order 20.7.4, paragraphs 6.1 and 10.1.</b>
      </p>
    </div>
  </div>
</template>

<script>
import StudentFAQ from '@/components/StudentFAQ.vue'

export default {
  components: {
    StudentFAQ
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/_presets.scss';

.list-container {
  display: inline-block;
}

.steps-list {
  list-style-type: square;
  text-align: left;
}
</style>
